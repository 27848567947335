import React from 'react';
import DocumentTitle from 'react-document-title';

function AppHomeActivationCode() {
    return (
        <DocumentTitle title="小青账-激活码活动">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>激活码活动</h1>
                    <br/>
                    <p>更新日期：2022年07月15日</p>
                    <p>生效日期：2022年07月23日</p>
                    <br/>
                    <p>引言</p>
                    <br/>
                    <p>激活码是免费兑换小青账VIP的渠道码，类型分为1周、1个月、3个月、6个月、12个月、3年、永久VIP，或其他时效激活码，获取激活码后可联系客服或者活动人员激活VIP会员。</p>
                    <br/>
                    <h3>1. 适用范围</h3>
                    <p>a) 激活码适用于所有小青账用户。</p>
                    <p>b) 违反法律规定或违反小青账规则行为及小青账已对您采取的措施。非法所得激活码，小青账有权进行回收操作。</p>
                    <br/>
                    <h3>2. 活动</h3>
                    <p>此活动至少可获取1周会员，以及更多时长的会员</p>
                    <br/>
                    <h3>3. 获取方式</h3>
                    <p>请查联系下方活动人员获取近期活动。</p>
                    <img width="200px" alt="" src="运行小哥.png"/>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default AppHomeActivationCode;
