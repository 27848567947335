import React, {useEffect, useState} from "react";
import DocumentTitle from 'react-document-title';
import {Button, message} from 'antd';

// import WeChatButton from './WeChatButton';


function Index() {

    // 地址栏中参数
    const [urlParameter, setUrlParameter] = useState();
    const [messageApi] = message.useMessage();

    useEffect(() => {
        getURLParameter();

    }, [1]);


    /**
     * 获取浏览器地址栏中的参数
     * @param name
     */
    function getURLParameter(name: string) {
        try {
            const url = window.location.href;
            console.log(url.split("?"))
            const param = url.split("?")
            // 注意：UrlParameter 为 code=xxx  并非只有值
            setUrlParameter(param[1]);
        } catch (e) {
            console.log(e)
        }
    }

    function JumpToAPP() {
        try {
            window.location = "xqzrich://codeMate?" + urlParameter;
            // return;
            //Scheme.postMessage("xqzrich://codeMate?code=" + urlParameter);
        } catch (e) {
            messageApi.warning("跳转有误,您可通过默认浏览器跳转~");
        }
    }

    return (
        <DocumentTitle title="快速通道">
            <div style={{width:"100%"}}>
                <div style={{position: "fixed", bottom: "16vh", left: "3vh"}}>
                    <p>点击立即前往</p>
                    <p>或通过默认浏览器跳转~</p>
                    <Button
                        style={{
                            backgroundColor: '#ffd289',
                            border: 'none',
                            display: 'block',
                            margin: 'auto',
                            marginTop: '.5vh'
                        }}
                        shape="round"
                        type="primary"
                        onClick={() => {

                            JumpToAPP();
                        }}
                    >立即前往</Button>
                    {/*<WeChatButton/>*/}
                </div>
                <img width="100%" alt="" src="推广-封面图 (1).png"/>
            </div>
        </DocumentTitle>

    );
}

export default Index;
